import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const ProjektTemplate = (props) => {
    const { items, linkTitle, linkHref, isEditMode } = props;
    console.log(linkTitle)
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';

    if (isInternalURL(href)) {
        link = (
            <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
                {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a href={href}>{linkTitle || href}</a>;
    }

    return (
        <>
            <div className="listing-item projekt-list projekt-heading">
                <div className="listing-body">
                    <h1>Projekte</h1>
                    <p>Laufende und bereits abgeschlossene Telemedizin-Projekte in der Gesundheitsregion Carus Consilium Sachsen</p>
                </div>
            </div>
            <div className="items">
                {items.map((item) => (
                    <ConditionalLink item={item} condition={!isEditMode} key={item['@id']}>
                        <div className="listing-item projekt-list slide-down">
                            <div className="listing-body">
                                <h4>{item.title ? item.title : item.id}</h4>
                                <p>weiterlesen</p>
                            </div>
                        </div>
                    </ConditionalLink>
                ))}
            </div>


            {link && <div className="footer">{link}</div>}
        </>
    );
};
ProjektTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};
export default ProjektTemplate;
